<template>
  <div class="container bgf5 pbs">
    <div v-if="actionText || strategyList.length" class="ptb8 plr15 bgf mb8">
      <div v-if="actionText" class="log-content tab-back bdr4 fs12 clth lh16">
        <div>
          <img class="log-icon" src="@/assets/img/common/log.svg" />
          记录
        </div>
        <div class="mt8 wb fw500">
          {{ actionText }}
        </div>
      </div>
      <div v-if="strategyList.length" class="strategy-know-box mt8 bdr5">
        <div class="strategy-know bdr4 pt8 plr12">
          <div class="flex flex-align-center fs16 fw500">
            <img
              class="title-icon mr8"
              src="@/assets/img/customization/hint.png"
            />
            经营策略必读
          </div>
          <div
            v-for="item in strategyList"
            :key="item.id"
            class="strategy-item cl69 fs12 wb ptb8 bdb"
          >
            <span class="fw500">{{ item.name }}:</span>
            {{ item.action_describe }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-for="field in dataSource"
      :key="field.key"
      class="module-box bgf plr15 mb8"
    >
      <div
        class="module-header relative pl10 flex flex-justify-between flex-align-center"
      >
        <div class="flex flex-align-center">
          <span class="fs16 fw500 mr8">{{ field.title }}</span>
          <div
            v-if="field.remark"
            class="icon-box"
            @click="showModal(field.remark)"
          >
            <img class="icon" src="@/assets/img/customization/help.svg" />
          </div>
        </div>
        <div
          v-if="field.jump_url && field.jump_url_title"
          class="view-more flex-s0 flex flex-align-center cl9c fs12"
          @click="onViewMore(field)"
        >
          查看更多
          <img class="icon" src="@/assets/img/customization/right_allow.svg" />
        </div>
      </div>
      <div
        v-if="field.button && field.button.length"
        class="module-content bdt"
      >
        <div
          v-for="lineIndex in captureDataNum(field.button)"
          :key="lineIndex"
          class="flex flex-align-center"
          :class="{ bdt: lineIndex > 1 }"
        >
          <div
            v-for="item in captureData(lineIndex, field.button)"
            :key="item.name"
            class="item-box pt4 pb12 flex flex-direction-column flex-align-center relative"
          >
            <div class="item-box-value fw500 flex flex-align-center">
              <div v-if="isNumberType(item.value)" class="fs20 tc wb">
                {{ ellipsisText(String(item.value || '0'), 3) }}
              </div>
              <div v-else class="fs12 tc wb">
                {{ item.value || '--' }}
              </div>
            </div>
            <div class="fs12 cl9c">{{ ellipsisText(item.name, 3) }}</div>
          </div>
        </div>
      </div>
      <!-- 剩余卡项 -->
      <div
        v-if="
          field.key === 'customer_remainder_card_item' &&
          field.list &&
          field.list.length
        "
        class="bdt"
      >
        <div
          v-for="(item, index) in field.isUnfold
            ? field.list
            : field.list.slice(0, 5)"
          :key="index"
          class="flex flex-justify-between flex-align-center ptb12"
          :class="{ bdt: index }"
        >
          <div class="flex-1 flex flex-align-center">
            <div class="cl69 lh20">{{ ellipsisText(item.name, 7) }}</div>
            <div
              v-if="item.use_date"
              class="fs12 bgth clff bdr2 h20 plr6 ml4 flex flex-align-center"
            >
              {{ item.use_date }}
            </div>
          </div>
          <span class="cl69 flex-s0 pl5">
            剩
            <span class="cl29">{{ item.remainder_time || 0 }}</span>
            次
          </span>
        </div>
      </div>
      <!-- 预约信息 -->
      <div
        v-else-if="
          field.key === 'customer_booking' && field.list && field.list.length
        "
        class="bdt"
      >
        <div
          v-for="(item, index) in field.isUnfold
            ? field.list
            : field.list.slice(0, 5)"
          :key="index"
          class="flex flex-justify-between flex-align-center ptb12"
          :class="{ bdt: index }"
        >
          <div class="flex-1 flex flex-align-center">
            <span class="cl9c lh17">{{ item.booking_date || '--' }}</span>
            <span class="cl69 lh17 ml4">{{ item.name || '--' }}</span>
          </div>
          <span class="cl29 flex-s0 pl5">
            {{ item.booking_status || '--' }}
          </span>
        </div>
      </div>
      <!-- 消费信息, 消耗信息 -->
      <div
        v-else-if="
          (field.key === 'customer_order' ||
            field.key === 'customer_verification_order') &&
          field.list &&
          field.list.length &&
          field.list[0]['order_detail'] &&
          field.list[0]['order_detail']['length']
        "
        class="bdt pb8"
      >
        <div class="mt8 tab-back bdr4 pt8 plr12">
          <div class="flex flex-align-center">
            <span class="clth">
              {{ field.list[0]['order_date'] || '--' }}
            </span>
            <div
              class="bgth clff ml6 h20 plr6 bdr2 fs12 flex flex-align-center"
            >
              {{ field.list[0]['use_date'] || '--' }}
            </div>
          </div>
          <div
            v-for="(item, index) in field.list[0]['order_detail']"
            :key="index"
            class="flex flex-justify-between flex-align-center ptb8 fs12 clth"
            :class="{ 'bdb-green': index }"
          >
            <div class="flex-1">
              <span>{{ ellipsisText(item.goods_name, 7) || '--' }}</span>
              <span class="ml4">x {{ item.goods_num || 0 }}</span>
            </div>
            <span>￥{{ item.goods_money || 0 }}</span>
          </div>
        </div>
      </div>
      <!-- 优惠券 -->
      <div
        v-else-if="
          field.key === 'customer_coupon' && field.list && field.list.length
        "
        class="bdt pb12"
      >
        <div
          v-for="(item, index) in field.isUnfold
            ? field.list
            : field.list.slice(0, 5)"
          :key="index"
          class="coupon-item flex flex-align-center bdr4 mt8"
        >
          <div
            class="coupon-sum flex-s0 flex flex-justify-center flex-align-center pl8 pr8"
          >
            <div v-if="item.coupon_value" class="goods-price">
              <div class="price-symbol">¥</div>
              <div class="price-num">{{ Number(item.coupon_value) }}</div>
            </div>
            <img
              v-else
              class="coupon-icon"
              src="@/assets/img/customization/coupon.svg"
            />
          </div>
          <div class="coupon-detail flex-1 ptb8 pr8">
            <div class="mb8">
              <div class="coupon-title">
                {{ ellipsisText(item.coupon_name, 7) }}
              </div>
              <div v-if="item.coupon_condition" class="fs12 cl9c line-1">
                {{ formatRichText(item.coupon_condition) }}
              </div>
            </div>
            <div class="flex flex-align-center fs12">
              <div v-if="item.coupon_date" class="cl9c flex-1">
                {{ item.coupon_date }}
              </div>
              <div
                v-if="item.coupon_date_day"
                class="flex-s0 bgth clff ml6 h20 plr6 bdr2 flex flex-align-center"
              >
                {{ item.coupon_date_day }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="field.list && field.list.length > 5"
        class="unfold-handle clth flex flex-justify-center flex-align-center bdt"
        @click="field.isUnfold = !field.isUnfold"
      >
        {{ field.isUnfold ? '收起' : '展开' }}
        <img
          class="icon ml6"
          :class="{ 'unfold-handle-acitve': field.isUnfold }"
          src="@/assets/img/common/bottom_allow_active.svg"
        />
      </div>
      <div
        v-if="
          (!field.button || !field.button.length) &&
          (!field.list || !field.list.length)
        "
        class="ptb12 tc cl9c bdt"
      >
        暂无{{ field.title }}
      </div>
    </div>
    <ModalView v-model="modalVisible" maskFun :showCancelButton="false">
      <div class="hint-content cl29 wb">{{ hintValue }}</div>
    </ModalView>
  </div>
</template>

<script>
import { getCustomerBusiness } from '@/service/customer.service.js'
import ModalView from '@/components/modalView/index.vue'

const MODULE_ENUM = [
  { key: 'customer_remainder_card_item', title: '剩余卡项' },
  { key: 'customer_booking', title: '预约信息' },
  // { key: 'customer_order', title: '消费信息',jump_url: '/customer-spendOrder', jump_url_title: '消费订单' },
  { key: 'customer_order', title: '消费信息' },
  { key: 'customer_verification_order', title: '消耗信息' },
  { key: 'customer_coupon', title: '优惠券' }
]

export default {
  name: 'business',
  components: { ModalView },
  props: {
    customerInfo: {
      // 会员详情
      type: Object,
      default: () => ({})
    },
    actionText: {
      // 记录
      type: String,
      default: () => ''
    },
    strategyList: {
      // 策略列表
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dataSource: MODULE_ENUM, // 模块枚举值
      depositList: [], // 寄存信息列表
      isUnfold: false, // 寄存信息是否展开
      memberInfo: {}, // 会员信息
      couponData: {}, // 优惠券信息
      verificationData: {}, // 核销信息
      orderData: {}, // 订单信息
      params: {}, // 路由参数
      modalVisible: false, // modal弹窗显示
      hintValue: '' // 弹窗文案
    }
  },
  mounted() {
    // const query = this.customerInfo
    // if (query) {
    //   this.params = query
    //   this.initData()
    // }
  },
  watch: {
    customerInfo(newval) {
      if (newval) {
        this.params = query
        this.initData()
      }
    }
  },
  computed: {},
  watch: {
    customerInfo(newval) {
      const query = this.$router.query
      if (!query) {
        this.params = newval
        this.initData()
      }
    }
  },
  methods: {
    // 组件激活
    // activated() {
    // 	this.initData()
    // },
    //初始化
    async initData() {
      try {
        const { map_idx_id } = this.params
        if (!map_idx_id) return this.$emit('hideSkeleton')
        const { data } = await getCustomerBusiness({ map_idx_id })

        const dataSource = [...this.dataSource]
        for (let i = 0; i < dataSource.length; i++) {
          const item = dataSource[i]
          if (
            data[item.key] &&
            Object.prototype.toString.call(data[item.key]) === '[object Object]'
          ) {
            dataSource[i] = { ...data[item.key], ...item }
          }
        }
        this.dataSource = [...dataSource]
        this.$emit('setMemberData', data.customer_info)
        this.$emit('hideSkeleton')
      } catch (error) {
        console.log(error)
      }
    },
    formatDate(date) {
      return /\d{4}-\d{1,2}-\d{1,2}/g.exec(date)[0]
    },
    // 计算数据有几行
    captureDataNum(data = []) {
      return Math.ceil(data.length / 4) || 0
    },
    // 截取数据
    captureData(index = 0, data = []) {
      const endIndex = index * 4
      const newData = [...data.slice((index - 1) * 4, endIndex)]
      return newData
    },
    // 显示弹窗
    showModal(val) {
      this.hintValue = val
      this.modalVisible = true
    },
    // 查看更多
    onViewMore({ jump_url }) {
      window.open(jump_url)
      // navigateTo('/pagesTool/toh5/index', { baseUrl: jump_url })
    },
    // 截取富文本dom标签符号
    formatRichText(html) {
      return html.replace(/<[^>]*>|/g, '')
    },
    // 文字超出省略 num: 前后保留多少位
    ellipsisText(value, num = 4) {
      if (!value) return ''
      if (value.length > 2 * num + 1) {
        let arr = value.split('')
        const newArr = [
          ...arr.slice(0, num),
          '...',
          ...arr.slice(arr.length - num, arr.length)
        ]
        return newArr.join('')
      } else {
        return value
      }
    },
    // 判断value是否是number类型
    isNumberType(value) {
      return Number.isFinite(value)
    }
  }
}
</script>

<style lang="less" scoped>
.tab-back {
  background: @tab-back;
}
.ml6 {
  margin-left: 6px;
}
.pt4 {
  padding-top: 4px;
}
.plr6 {
  padding-left: 6px;
  padding-right: 6px;
}
.pl8 {
  padding-left: 8px;
}
.pr8 {
  padding-right: 8px;
}
.pb8 {
  padding-bottom: 8px;
}
.ml4 {
  margin-left: 4px;
}
.h20 {
  height: 20px;
}
.title-icon {
  width: 28px;
  height: 28px;
}
.bdb-green {
  border-top: 1px solid rgba(2, 89, 73, 0.1);
}
.container {
  min-height: 100vh;

  .log-content {
    padding: 8px 12px;

    .log-icon {
      width: 16px;
      height: 16px;
      vertical-align: bottom;
    }
  }
  .strategy-know-box {
    background: linear-gradient(
      180deg,
      rgba(10, 87, 217, 0.3),
      rgba(10, 87, 217, 0.11) 100%
    );
    padding: 1px;

    .strategy-know {
      background: linear-gradient(180deg, #e7f0ff 0%, #ffffff 100%);
    }
    .strategy-item:last-child {
      border: none;
    }
  }
  .module-box {
    .module-header {
      height: 44px;

      &::after {
        width: 4px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        content: '';
        background: @theme-color;
      }
      .view-more {
        height: 100%;
      }
    }
    .module-content {
      .item-box {
        width: 25%;

        &::after {
          position: absolute;
          content: '';
          background-color: #f2f3f5;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto 0;
          width: 1px;
          height: 32px;
        }
      }
      .item-box:last-child::after {
        width: 0;
      }
      .item-box-value {
        height: 44px;
      }
    }
  }
  .unfold-handle {
    height: 34px;
    transform: rotate(0deg);
  }
  .unfold-handle-acitve {
    transform: rotate(180deg);
  }
  .coupon-item {
    background: #f9fafb;
    border: 1px solid #f2f3f5;

    .coupon-sum {
      min-width: 20%;

      .goods-price {
        height: 26px;
        line-height: 26px;
      }
      .price-num {
        font-size: 24px;
      }
      .coupon-icon {
        width: 46px;
        height: 46px;
      }
    }
    .coupon-detail {
      .coupon-title {
        margin-bottom: 4px;
      }
    }
  }
  .hint-content {
    padding: 14px 33px;
    white-space: pre-line;
  }
}
</style>
