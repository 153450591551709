<template>
  <div class="container-dynamic relative pbs">
    <div
      class="dynamic-select pl15 pr15 relative"
      @touchmove.stop.prevent="moveHandle"
    >
      <div class="drop-down-menu-we flex relative">
        <div
          class="drop-down-btn flex flex-align-center flex-justify-center flex-1 bgf"
          @click="btnToggleMenu('calendar')"
        >
          <div
            class="drop-down-title fs16 mr5 line-1"
            :class="[
              dropDownObj.activeMenu == 'calendar' && dropDownObj.coverShow
                ? 'clth'
                : ''
            ]"
          >
            {{ reacData.selectedCalendar || '日历' }}
          </div>
          <img
            :src="
              dropDownObj.activeMenu == 'calendar' && dropDownObj.coverShow
                ? dropDownActiveIcon
                : dropDownIcon
            "
            class="member-select-icon"
          />
        </div>
        <div
          class="drop-down-btn flex flex-align-center flex-justify-center flex-1 bgf"
          @click="btnToggleMenu('type')"
        >
          <div
            class="drop-down-title fs16 mr5 line-1"
            :class="[
              dropDownObj.activeMenu == 'type' && dropDownObj.coverShow
                ? 'clth'
                : ''
            ]"
          >
            {{ reacData.selectedType.name || '客户类型' }}
          </div>
          <img
            :src="
              dropDownObj.activeMenu == 'type' && dropDownObj.coverShow
                ? dropDownActiveIcon
                : dropDownIcon
            "
            class="member-select-icon"
          />
        </div>
        <div
          class="drop-down-btn flex flex-align-center flex-justify-center flex-1 bgf"
          @click="btnToggleMenu('dynamic')"
        >
          <div
            class="drop-down-title fs16 mr5 line-1"
            :class="[
              dropDownObj.activeMenu == 'dynamic' && dropDownObj.coverShow
                ? 'clth'
                : ''
            ]"
          >
            {{ reacData.selectedDynamic.key || '动态分组' }}
          </div>
          <img
            :src="
              dropDownObj.activeMenu == 'dynamic' && dropDownObj.coverShow
                ? dropDownActiveIcon
                : dropDownIcon
            "
            class="member-select-icon"
          />
        </div>
        <div
          v-show="dropDownObj.coverShow"
          class="drop-down-cover"
          @click="dropDownObj.coverShow = !dropDownObj.coverShow"
        >
          <!-- 日历 -->
          <div
            v-show="dropDownObj.activeMenu == 'calendar'"
            @click.stop=""
            class="bgf"
          >
            <ShowCalendar
              v-if="params.qw_external_userid || params.crm_yw_id"
              :yw-id="params.qw_external_userid || params.crm_yw_id"
              @selectDate="(time) => seachHandle('time', time)"
            />
          </div>
          <!-- 客户类型 -->
          <div
            v-show="dropDownObj.activeMenu == 'type'"
            class="pt10 pb10 bgf flex flex-wrap pl10 pr10"
            @click.stop=""
          >
            <div
              v-for="item in reacData.subjectList"
              :key="item.id"
              class="p6 bgf mb5 subjects-key-box"
              @click="seachHandle('subject', item)"
            >
              <div
                :class="[
                  'flex flex-justify-center flex-align-center flex-g1 flex-s1 subjects-key-item',
                  reacData.selectedType.id === item.id
                    ? 'subjects-key-item-select'
                    : ''
                ]"
              >
                <img
                  :src="getImageUrl(item.tag_platform_type)"
                  class="subjects-key-img"
                />
                <div class="subjects-key-name">{{ item.name }}</div>
              </div>
            </div>
          </div>
          <!-- 动态分组 -->
          <div v-show="dropDownObj.activeMenu == 'dynamic'" @click.stop="">
            <van-cell
              v-for="item in reacData.dynamicTypes"
              :key="item.value"
              @click.stop="seachHandle('dynamic', item)"
            >
              <template #title>
                <span
                  :class="[
                    item.value === reacData.selectedDynamic.value
                      ? 'dynamic-type-title-select'
                      : 'dynamic-type-title'
                  ]"
                >
                  {{ item.key }}
                </span>
              </template>
              <template #right-icon>
                <img
                  class="select-allow-icon"
                  v-if="item.value === reacData.selectedDynamic.value"
                  :src="checkIcon"
                  alt=""
                />
              </template>
            </van-cell>
          </div>
        </div>
      </div>
    </div>
    <van-cell-group :border="false">
      <van-cell :border="false">
        <template #title>
          <van-list
            v-model:loading="reacData.loading"
            :finished="reacData.isFinished"
            :finished-text="
              reacData.dynamicList.length === 0
                ? '当前客户暂无动态'
                : '没有更多了'
            "
            :immediate-check="false"
            @load="getDynamicList"
          >
            <div
              class="logs-item"
              v-for="(item, idx) in reacData.dynamicList"
              :key="idx"
            >
              <div class="item-date">
                <div class="item-date-content">
                  {{ item.time }}
                </div>
              </div>
              <template v-for="dynamic in item.list" :key="dynamic.id">
                <DynamicList :dynamic-obj="dynamic" dynamic-type="customer" />
              </template>
              <div class="create-time" v-if="item.createTime">
                {{ item.create_time }}
              </div>
            </div>
          </van-list>
          <!-- <div class="trend-list-box">
            <div
              class="logs-item"
              v-for="(item, idx) in reacData.dynamicList"
              :key="idx"
            >
              <div class="item-date">
                <div class="item-date-content">
                  {{ item.time }}
                </div>
              </div>
              <template v-for="dynamic in item.list" :key="dynamic.id">
                <DynamicList :dynamic-obj="dynamic" dynamic-type="customer" />
              </template>
              <div class="create-time" v-if="item.createTime">
                {{ item.create_time }}
              </div>
            </div>
          </div> -->
        </template>
      </van-cell>
      <!-- <div class="list-bootom-hint fs12 cl99 tc bgf">
        {{
          reacData.loading
            ? '努力加载中~'
            : reacData.isFinished
            ? '- 别滑了，咱们都是有底线的 -'
            : ''
        }}
      </div> -->
    </van-cell-group>
  </div>
</template>

<script setup>
import {
  getCustomerNews,
  getSubjectsKey,
  getDynamicType
} from '@/service/customer.service.js'
// import { navigateTo, redirectTo } from '@/utils/router.js'
import { reactive, ref, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import ShowCalendar from '../../../community/components/ShowCalendar.vue'
import DynamicList from '../../../community/components/DynamicList.vue'
import { Toast } from 'vant'
import checkIcon from '@/assets/img/customer-portrait/check.svg'
import dropDownActiveIcon from '@/assets/img/common/drop-down-active.svg'
import dropDownIcon from '@/assets/img/common/drop-down.svg'
// import { onShow } from '@dcloudio/uni-app'
// import { getRouteParams } from '@/utils/router.js'

const props = defineProps({
  customerInfo: {
    // 会员详情
    type: Object,
    default: () => ({})
  }
})
const dropDownObj = reactive({
  coverShow: false,
  activeMenu: ''
})
const route = useRoute()
// const dropdownCalendar = ref(null)
// const dropdownSubject = ref(null)
// const dropdownDynamic = ref(null)
// const holdBack = ref(false)
const reacData = reactive({
  subjectList: [], // 主体列表(客户类型)
  dynamicTypes: [], // 动态类型
  currentPage: 0, // 当前页面
  pageSize: 20, // 每页的数量
  selectedCalendar: '', // 选中的日历时间
  selectedType: {}, // 选中的客户类型
  selectedDynamic: {}, // 选中的动态分组
  dynamicList: [], // 动态列表
  loading: false, // 列表加载状态
  isFinished: false // 列表是否加载完成
})
const params = ref({}) // 路由参数

// 禁止滑动固定位置滚动页面
const moveHandle = () => {}
onMounted(() => {
  // const query = props.customerInfo
  // if (query) {
  //   params.value = query
  //   initData()
  // }
  // console.log('11111111111', query, JSON.stringify(query))
})

watch(
  () => props.customerInfo,
  (newval) => {
    if (newval) {
      params.value = newval
      initData()
    }
  }
)
// 暴露加载函数
// defineExpose({ getDynamicList })
// 筛选条件
const seachHandle = (key, data) => {
  const { selectedType, selectedDynamic, selectedCalendar } = reacData

  switch (key) {
    case 'subject':
      // console.log('dropdownSubject.value', dropdownSubject.value)
      reacData.selectedType = selectedType.id === data.id ? {} : data
      // dropdownSubject.value.toggle(false)
      toggleMenu('', false)
      break
    case 'dynamic':
      reacData.selectedDynamic =
        selectedDynamic.value === data.value ? {} : data
      // dropdownDynamic.value.toggle(false)
      // console.log('------------')
      toggleMenu('', false)
      break
    case 'time':
      reacData.selectedCalendar = selectedCalendar === data ? '' : data
      // dropdownCalendar.value.toggle(false)
      toggleMenu('', false)
      break
    default:
      break
  }
  reacData.currentPage = 0
  reacData.isFinished = false
  reacData.dynamicList = []

  getDynamicList()
}

const btnToggleMenu = (key) => {
  if (dropDownObj.activeMenu == key) {
    dropDownObj.coverShow = !dropDownObj.coverShow
  } else {
    dropDownObj.coverShow = true
  }
  dropDownObj.activeMenu = key
}

const toggleMenu = (key, isOpen) => {
  dropDownObj.activeMenu = key
  dropDownObj.coverShow = isOpen

  // console.log('遮盖层状态', dropDownObj.coverShow)
}

// const scrolltolower = () => {
//   getDynamicList()
// }

const getDynamicList = async () => {
  if (reacData.isFinished) {
    return false
  }
  try {
    // 阻止重复请求
    // if (holdBack.value) {
    // 	return false
    // } else {
    // 	holdBack.value = true
    // }

    const {
      dynamicList,
      currentPage,
      pageSize,
      selectedType,
      selectedDynamic,
      selectedCalendar
    } = reacData
    const { qw_external_userid, crm_yw_id } = params.value

    const body = {
      page: currentPage + 1,
      limit: pageSize,
      show_type: 2,
      yw_id: qw_external_userid || crm_yw_id
    }
    // subject_data_id: props.customerInfo.qw_external_userid,
    if (selectedType) body.subject_id = selectedType.id
    if (selectedDynamic) body.type = selectedDynamic.value
    if (selectedCalendar) {
      body.start_time = selectedCalendar
      body.end_time = selectedCalendar
    }
    reacData.loading = true
    const { data } = await getCustomerNews(body)
    reacData.loading = false
    // holdBack.value = false
    console.log('获取数据', data)
    if (data && data.data && data.data.length) {
      reacData.currentPage++
      const list = [...dynamicList]
      if (list[list.length - 1]?.time === data.data[0]?.time) {
        list[list.length - 1]?.list.push(...data.data[0]?.list)
      } else {
        list.push(...data.data)
      }
      reacData.dynamicList = [...list]
    }

    if (data.count <= body.page * pageSize) {
      reacData.isFinished = true
    }
    return Promise.resolve(true)
  } catch (error) {
    console.log(error)
  }
}
// 初始化
const initData = async () => {
  try {
    const { qw_external_userid, crm_yw_id } = params.value
    const [{ data: dynamicTypes }, { data: subjectsKey }] = await Promise.all([
      getDynamicType(),
      getSubjectsKey(qw_external_userid || crm_yw_id)
    ])

    reacData.currentPage = 0
    reacData.dynamicList = []
    reacData.isFinished = false
    reacData.dynamicTypes = dynamicTypes
    reacData.subjectList = subjectsKey.subject_list
    console.log('初始化请求', reacData)
    // Toast.loading({
    //   message: '加载中',
    //   forbidClick: true,
    //   duration: 0
    // })
    // uni.showLoading({ title: '加载中' })
    await getDynamicList()
    Toast.clear()
    // uni.hideLoading()
  } catch (error) {
    Toast.clear()
    // uni.hideLoading()
    console.log(error)
  }
}
// 获取图标地址
const getImageUrl = (key) => {
  return require(`@/assets/img/customer-portrait/${key}.svg`)
}
defineExpose({ getDynamicList, initData })
</script>

<style lang="less" scoped>
.p6 {
  padding: 6px;
}
.plr6 {
  padding-left: 6px;
  padding-right: 6px;
}
.icon {
  width: 16px;
  height: 16px;
}
.container-dynamic {
  min-height: 100vh;
  // overflow-y: scroll;
}
.dynamic-select {
  // position: -webkit-sticky; /* Safari */
  // position: sticky;
  // position: fixed;
  // top: 312rpx;
  // left: -30rpx;
  // z-index: 99;
  width: 100vw;
  // box-shadow: none;

  .drop-down-menu-we {
    border-bottom: 1px solid #eee;

    .member-select-icon {
      display: block;
      width: 16px;
      height: 16px;
    }
    .drop-down-btn {
      height: 48px;
      line-height: 48px;
      // max-width: 245rpx;
      width: 33.3%;
    }
    .drop-down-title {
      max-width: 75px;
    }

    .drop-down-cover {
      position: absolute;
      top: 48px;
      left: -15px;
      z-index: 100;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.7);
    }
  }
  // :deep(.van-dropdown-menu) {
  // 	box-shadow: none;
  // }
}
// .trend-list-box {
// 	height: calc(100);
// }
.subjects-key-box {
  width: 50%;
  box-sizing: border-box;
}
.subjects-key-item {
  width: 168px;
  height: 40px;
  background: #ebf2f0;
  border-radius: 2px;
  margin: auto;
}

.list-bootom-hint {
  height: 44px;
  line-height: 44px;
}
.select-allow-icon {
  width: 24px;
  height: 24px;
}

.subjects-key-item-select {
  border: 1px solid @theme-color;
}
.subjects-key-name {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #171717;
}
.subjects-key-img {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
.dynamic-type-title {
  font-size: 14px;
  font-weight: 400;
  color: #171717;
}
.dynamic-type-title-select {
  font-size: 14px;
  font-weight: 500;
  color: @theme-color;
}
</style>
