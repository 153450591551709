<template>
  <Skeleton v-show="pageLoading || childPageLoading" />
  <div
    v-show="!pageLoading && !childPageLoading"
    ref="containerView"
    class="container-view bgf fs14"
  >
    <div class="relative">
      <div class="customer-info-header plr15 flex flex-align-center">
        <!-- <div class="user-record-write relative">
            <van-field
              v-model="recordText"
              autosize
              type="textarea"
              maxlength="800"
              placeholder="记录用户所说的重点！"
            />
            <div
              v-if="!clickFouce"
              class="cover-layer"
              @click.stop="clickFouce = true"
            />
            <div
              v-if="clickFouce"
              class="user-record-handle flex flex-align-center"
            >
              <span class="fs12 cl99 mr8">{{ `${recordText.length}/800` }}</span>
              <van-button type="primary" @click="onSubmit" class="submit-btn">
                确认
              </van-button>
            </div>
            <div v-else class="user-record-handle">
              <img
                class="write-icon"
                src="@/assets/img/customer-portrait/writing-fluently.svg"
              />
            </div>
          </div> -->

        <img
          class="customer-image flex-s0 mr12"
          :src="
            dataSource.pic || require('@/assets/img/common/DefaultAvatar.png')
          "
        />
        <div class="customer-detail flex-1">
          <div
            v-if="
              dataSource.tmp_style?.title_name || dataSource.tmp_style?.cu_id
            "
            class="flex flex-align-center"
          >
            <text
              class="fs16 cl29 fw500 customer-name line-1"
              v-if="dataSource.tmp_style.title_name"
            >
              {{ dataSource.tmp_style.title_name }}
            </text>
            <text
              class="fs16 cl29 fw500 mr8 customer-name"
              v-if="dataSource.tmp_style.cu_id"
            >
              ({{ dataSource.tmp_style.cu_id }})
            </text>
            <text class="fs12 cl69 mr8 fw500 customer-name">
              {{ dataSource.tmp_style.grede_marker }}
            </text>
          </div>
          <!-- <div v-else class="fs16 fw500 cl29 line-1">
              {{
                dataSource.appellation ||
                dataSource.nickname ||
                (dataSource.phone ? mobileSensitivity(dataSource.phone) : '--')
              }}
            </div> -->
          <div
            v-if="
              String(dataSource.qw_type) !== '0' &&
              String(dataSource.deleted_state) === '1'
            "
            class="customer-info-detail flex flex-align-center fs12 mt8"
          >
            <span
              class="fw400 cl69 line-1"
              :class="{
                fs16:
                  dataSource.tmp_style.grede_marker &&
                  !dataSource.tmp_style.title_name &&
                  !dataSource.tmp_style.cu_id,
                'customer-name':
                  dataSource.tmp_style.grede_marker &&
                  !dataSource.tmp_style.title_name &&
                  !dataSource.tmp_style.cu_id
              }"
            >
              {{ dataSource.qw_external_name || dataSource.nickname }}
            </span>
            <span
              class="customer-corp flex-s0 cl39"
              :class="{ clf6: String(dataSource.qw_type) !== '1' }"
            >
              {{ String(dataSource.qw_type) === '1' ? '@微信' : '@企业微信' }}
            </span>
            <img
              v-if="dataSource.sex && dataSource.sex !== '未知'"
              class="customer-sex-icon flex-s0"
              :src="getUserSexIcon(dataSource.sex)"
            />
            <span
              class="fs12 cl69 fw500"
              v-if="
                dataSource.tmp_style.grede_marker &&
                !dataSource.tmp_style.title_name &&
                !dataSource.tmp_style.cu_id
              "
            >
              {{ dataSource.tmp_style.grede_marker }}
            </span>
          </div>
        </div>
        <div class="handle-btn-box flex-s0" @click.stop="handleIncident">
          <button
            class="handle-btn fs24"
            :class="{
              'orange-theme':
                String(dataSource.deleted_state) !== '1' ||
                String(dataSource.qw_type) === '0'
            }"
          >
            {{
              String(dataSource.qw_type) !== '0' &&
              String(dataSource.deleted_state) === '1'
                ? '发微信'
                : '加好友'
            }}
          </button>
        </div>
      </div>
      <div class="flex flex-align-center pb3 pt12">
        <div
          v-for="field in MEMBER_FIELD"
          :key="field.value"
          class="flex-1 flex flex-direction-column flex-align-center"
        >
          <span class="clth fs20 fw500 mb4 lh24">
            {{
              `${field.value === 'customer_money' ? '￥' : ''}${
                memberData[field.value] || 0
              }`
            }}
          </span>
          <span class="fs12 cl69">{{ field.label }}</span>
        </div>
      </div>
      <div
        id="tabs-nav-wrap"
        class="tabs-nav-wrap flex flex-justify-evenly flex-align-center bgf"
      >
        <div
          v-for="(tab, index) in TABS_LIST"
          :key="index"
          class="tab-nav cl9c relative line-1 tc fs16"
          :class="{
            'tab-nav-active': tab.value === activeKey
          }"
          @click="onSelect(tab, index)"
        >
          {{ tab.label }}
        </div>
      </div>
      <div :style="{ paddingBottom: handleHeight + 'px' }">
        <BusinessView
          v-show="activeKey === 'business'"
          ref="business"
          :customerInfo="dataSource"
          :actionText="actionText"
          :strategyList="strategyList"
          @hideSkeleton="hideSkeleton"
          @setMemberData="setMemberData"
        />
        <LabelView
          v-show="activeKey === 'label'"
          ref="label"
          :customerInfo="dataSource"
          :tagSubData="tag_sub_data"
          @onRefresh="getDataSource"
          @onShowModal="onShowModal"
        />
        <DynamicView
          v-show="activeKey === 'dynamic'"
          ref="dynamic"
          :customerInfo="dataSource"
        />
      </div>
      <div
        class="handle-container flex flex-justify-evenly flex-align-center bgf fs12 cl9c bdt"
      >
        <div v-for="item in HANDLE_ENUM" :key="item.value">
          <div
            v-if="item.value !== 'call' || !dataSource.phone"
            class="flex flex-direction-column flex-align-center"
            :class="{ 'handle-disabled': handleDisabled(item.value) }"
            :style="{ color: item.value === 'wechat' ? '#50B674' : '' }"
            @click="onHandle(item.value)"
          >
            <img
              class="handle-icon mb6"
              :src="
                require(`@/assets/img/customer-portrait/${item.icon}${
                  item.value !== 'wechat' && handleDisabled(item.value)
                    ? '-disabled'
                    : ''
                }.svg`)
              "
            />
            <!-- <img
                              v-show="item.value !== 'wechat' && handleDisabled(item.value)"
                              class="handle-icon mb6"
                              :src="
                                  require(`@/assets/img/customer-portrait/${item.icon}-disabled.svg`)
                              "
                          /> -->
            <span>
              {{
                item.value === 'wechat'
                  ? String(dataSource.qw_type) !== '0' &&
                    String(dataSource.deleted_state) === '1'
                    ? item.label
                    : '加好友'
                  : item.label
              }}
            </span>
          </div>
          <a
            v-else
            :href="'tel:' + dataSource.phone"
            :style="{
              color: handleDisabled(item.value) ? '#c9cdd4' : '#86909c'
            }"
          >
            <div
              class="flex flex-direction-column flex-align-center"
              :class="{ 'handle-disabled': handleDisabled(item.value) }"
              :style="{ color: item.value === 'wechat' ? '#50B674' : '' }"
              @click="onHandle(item.value)"
            >
              <img
                class="handle-icon mb6"
                :src="
                  require(`@/assets/img/customer-portrait/${item.icon}${
                    item.value !== 'wechat' && handleDisabled(item.value)
                      ? '-disabled'
                      : ''
                  }.svg`)
                "
              />
              <!-- <img
                                  v-show="item.value !== 'wechat' && handleDisabled(item.value)"
                                  class="handle-icon mb6"
                                  :src="
                                      require(`@/assets/img/customer-portrait/${item.icon}-disabled.svg`)
                                  "
                              /> -->
              <span>
                {{
                  item.value === 'wechat'
                    ? String(dataSource.qw_type) !== '0' &&
                      String(dataSource.deleted_state) === '1'
                      ? item.label
                      : '加好友'
                    : item.label
                }}
              </span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <ModalView :showCancelButton="false" v-model="hintModalVisible">
      <div
        class="hint-modal-view flex flex-direction-column flex-justify-center flex-align-center"
      >
        <span>会员无手机号</span>
        <span>请通过其他方式添加微信</span>
      </div>
    </ModalView>
    <ModalView
      v-model="modalVisible"
      title="编辑称呼"
      async-close
      @confirm="editAppellation"
      @cancel="appellationValue = ''"
    >
      <div class="appellation-modal flex flex-justify-center mb16">
        <div class="relative flex">
          <input
            :value="appellationValue"
            class="appellation-input"
            placeholder="编辑称呼"
            :maxlength="maxlength"
            :placeholder-style="{ color: '#E5E6EB' }"
            @blur="onBlur"
            @input="onInput"
            @focus="onFocus"
          />
          <span class="appellation-input-count fw400 cl9c lh20">
            {{ appellationValue.length }}/10
          </span>
        </div>
      </div>
    </ModalView>
  </div>
</template>

<script>
import {
  getMemberInfo,
  getMobileByExternalUserid,
  setMemberAppellation,
  addFlollow,
  updateRemark
} from '@/service/customer.service.js'
import BusinessView from './components/business.vue'
import LabelView from './components/label.vue'
import DynamicView from './components/dynamic.vue'
import ModalView from '@/components/modalView/index.vue'
import {
  getContext,
  openEnterpriseChat,
  navigateToAddCustomer,
  setClipboardData,
  getCurExternalContactPolling
} from '@/utils/nwechat.js'
// import { getRouteParams, navigateTo } from '@/utils/router.js'
// import { debounce } from '@/utils/index.js'
import { Toast, Dialog, Popup } from 'vant'
import Skeleton from './components/skeleton.vue'

const TABS_LIST = [
  { value: 'business', label: '客户商机' },
  { value: 'label', label: '资料标签' },
  { value: 'dynamic', label: '客户动态' }
]
const HANDLE_ENUM = [
  { value: 'edit', label: '改称呼', icon: 'detail' },
  { value: 'tagged', label: '打标签', icon: 'label' },
  { value: 'record', label: '记要点', icon: 'log' },
  { value: 'call', label: '打电话', icon: 'call' },
  { value: 'wechat', label: '发微信', icon: 'wechat' }
]
const MEMBER_FIELD = [
  { value: 'customer_money', label: '余额' },
  { value: 'customer_grade', label: '积分' },
  { value: 'customer_coupon_num', label: '优惠券' },
  { value: 'card_item_num', label: '卡项' }
]

export default {
  name: 'customer-newPortrait',
  components: {
    BusinessView,
    LabelView,
    DynamicView,
    ModalView,
    Skeleton
  },
  data() {
    return {
      TABS_LIST, // tabs数据
      HANDLE_ENUM, // 底部handle枚举
      activeKey: 'business', // 选中的tab
      dataSource: {}, // 会员详情
      actionText: '', // 记录
      strategyList: [], // 策略列表
      hintModalVisible: false, // 提示框显示
      params: {}, // 路由参数
      minHeight: 0, // tabs内容区域最小高度
      handleHeight: 64, // handle区域高度
      tabsTop: 0, // tabs距离顶部距离
      MEMBER_FIELD, // 会员字段
      memberData: {}, // 会员数据
      // scrollTop: 0, // scroll滚动top
      // oldScrollTop: 0, // 旧scroll滚动top
      // isMember: false, // 是否是会员
      modalVisible: false, // 编辑称呼弹窗显示
      appellationValue: '', // 称呼value
      maxlength: 10, // input限长
      // recordText: '', // 记录文本
      // clickFouce: false,
      pageLoading: true, // 骨架屏加载
      childPageLoading: true, // 子组件骨架屏加载
      isReady: false, // 页面是否完成首次加载
      tag_sub_data: [] // 批量打标签需要用到的主体id 数组
    }
  },
  mounted() {
    console.log('客户画像mounted', this.isReady)
  },
  async activated() {
    console.log('客户画像activated', this.isReady)
    if (!this.isReady) {
      this.params = this.$route.query
      Toast.loading({
        message: '内容加载中',
        forbidClick: true,
        duration: 0
      })
      await this.getContext()
      await this.enterType()
      this.isReady = true
    } else {
      await this.getDataSource()
      await this.updateLabel()
      if (this.$refs.dynamic && this.$refs.dynamic.initData) {
        this.$refs.dynamic.initData()
      }
    }
  },
  computed: {},
  watch: {
    pageLoading(newval) {
      if (!newval && !this.childPageLoading) {
        this.getDomData()
        Toast.clear()
      }
    },
    childPageLoading(newval) {
      if (!newval && !this.pageLoading) {
        this.getDomData()
        Toast.clear()
      }
    }
  },
  methods: {
    // setIsMember(boo) {
    //   this.isMember = boo
    // },
    hideSkeleton() {
      this.childPageLoading = false
    },
    // 获取用户性别图标
    getUserSexIcon(type) {
      const man = ['男', 'man', '1', 1]
      const women = ['女', 'woman', '2', 2]

      if (!type) return ''
      if (man.includes(type)) {
        return require(`@/assets/img/common/man.png`)
      } else if (women.includes(type)) {
        return require(`@/assets/img/common/women.png`)
      } else {
        return ''
      }
    },
    // 手机号敏感处理
    mobileSensitivity(val) {
      if (!val) return ''
      var reg = /^(\d{3})\d{4}(\d{4})$/
      return val.replace(reg, '$1****$2')
    },
    // 获取dom信息
    getDomData() {
      // const _this = this
      // const query = uni.createSelectorQuery().in(this)
      // this.$nextTick(function () {
      //   const { windowHeight } = uni.getSystemInfoSync()
      //   query
      //     .select('.tabs-nav-wrap')
      //     .boundingClientRect((data) => {
      //       _this.tabsTop = data.top
      //       _this.minHeight = windowHeight - data.height
      //     })
      //     .exec()
      //   query
      //     .select('.handle-container')
      //     .boundingClientRect((data) => {
      //       _this.handleHeight = data.height
      //     })
      //     .exec()
      // })
      this.$nextTick(() => {
        const tabsNavWrap = document.getElementById('tabs-nav-wrap')
        this.tabsTop = tabsNavWrap.offsetTop
      })
    },
    // 页面滑动事件
    // onScroll: this.debounce(function ({ detail }) {
    //   this.oldScrollTop = detail.scrollTop
    // }, 600),
    // debounce(func, wait=500, immediate=false) {
    // 	var timeout
    // 	return function() {
    // 		var context = this
    // 		var args = arguments

    // 		if (timeout) clearTimeout(timeout)
    // 		if (immediate) {
    // 			// 如果已经执行过，不再执行
    // 			var callNow = !timeout
    // 			timeout = setTimeout(function() {
    // 				timeout = null
    // 			}, wait)
    // 			if (callNow) func.apply(context, args)
    // 		} else {
    // 			timeout = setTimeout(function() {
    // 				func.apply(context, args)
    // 			}, wait)
    // 		}
    // 	}
    // },
    // 获取客户画像详情
    async getDataSource() {
      const { crm_yw_id, qw_external_userid } = this.params
      const body = {}
      if (crm_yw_id) body.crm_yw_id = crm_yw_id
      if (qw_external_userid) body.qw_external_userid = qw_external_userid
      const { code, data } = await getMemberInfo(body)

      if (this.pageLoading) this.pageLoading = false
      if (code == 200) {
        this.dataSource = {
          ...data.member_info,
          other_info: data.other_info || [],
          tmp_style: data.tmp_style || {}
        }
        this.actionText = data.action || ''
        this.strategyList = data.strategy_list || []
        this.tag_sub_data = data.tag_data || []
      }
    },
    // 调用接口更新备注
    async updateLabel() {
      const { qw_external_userid } = this.params
      const body = {}
      body.qw_external_userid = qw_external_userid
      await updateRemark(body)
    },
    // 选择tab
    onSelect({ value }) {
      const _this = this
      // this.scrollTop = this.oldScrollTop
      this.$nextTick(() => {
        if (_this.$refs.containerView) {
          // _this.$refs.containerView.scrollTop = 72
          _this.$refs.containerView.scrollTop = this.tabsTop
        }
      })
      this.activeKey = value
    },
    // 前往聊天或加好友
    async handleIncident() {
      const { qw_type, deleted_state } = this.dataSource
      // Toast.loading({
      //   message: '加载中',
      //   forbidClick: true,
      //   duration: 0
      // })
      // uni.showLoading({ title: '加载中', mask: true })
      // deleted_state 枚举值: 1-互为好友关系 2-成员删除客户 3-客户删除成员
      if (String(qw_type) !== '0' && String(deleted_state) !== '2') {
        this.openEnterpriseChat()
      } else {
        this.navigateToAddCustomer()
      }
      // Toast.clear()
      // uni.hideLoading()
    },
    // 前往聊天窗口
    async openEnterpriseChat() {
      try {
        const { qw_external_userid } = this.dataSource
        await openEnterpriseChat({ externalUserIds: qw_external_userid })
        // window.close()
      } catch (error) {
        console.log(error)
        this.navigateToAddCustomer()
      }
    },
    // 前往加好友窗口
    async navigateToAddCustomer() {
      const { phone } = this.dataSource
      if (!phone) return (this.showHintModal = true)
      const res = await setClipboardData(phone)
      if (res) {
        Dialog.alert({
          message: '已成功复制客户手机号'
        }).then(() => {
          navigateToAddCustomer()
        })
      }
    },
    // 点击操作
    async onHandle(key) {
      const { phone, qw_external_userid } = this.dataSource
      if (this.handleDisabled(key)) return false
      switch (key) {
        case 'edit':
          this.onShowModal()
          break
        case 'tagged':
          this.$router.push({
            name: 'customer-tag',
            query: { id: qw_external_userid }
          })
          break
        case 'record':
          this.$router.push({
            name: 'customer-follow',
            params: { id: qw_external_userid, type: 'portraut' }
          })
          break
        case 'call':
          const { phone } = this.dataSource
          if (!phone) return (this.showHintModal = true)
          break
        case 'wechat':
          this.handleIncident()
          break
        default:
          break
      }
    },
    // 滚动到底部
    // scrolltolower() {
    //   const { activeKey } = this
    //   if (this.$refs[activeKey] && this.$refs[activeKey]['scrolltolower']) {
    //     this.$refs[activeKey].scrolltolower()
    //   }
    // },
    // 操作禁用
    handleDisabled(key) {
      const { dataSource } = this
      const { phone, qw_type, deleted_state } = dataSource

      if (key === 'tagged' || key === 'record') {
        return String(qw_type) === '0' || String(deleted_state) !== '1'
      } else if (key === 'call') {
        return !phone
      } else {
        return false
      }
    },
    // 根据外部联系人id获取mapping上的樊文花会员id
    async getMemberMappingInfo() {
      const { qw_external_userid } = this.params
      const { data, code } = await getMobileByExternalUserid({
        qw_external_userid
      })
      if (code == 200) {
        this.params = { ...this.params, ...data }
      }
    },
    // 获取聊天工具栏入口环境
    async getContext() {
      const entry = await getContext()
      switch (entry) {
        case 'single_chat_tools':
          break
        case 'group_chat_tools':
          this.$router.replace({ name: 'community-detail-new' })
          break
        default:
          Toast.error('入口环境错误!')
          break
      }
    },
    // 判断是否工具栏入口
    async enterType() {
      let { qw_external_userid } = this.params
      if (!qw_external_userid) {
        // this.params.qw_external_userid = 'wm1DICCQAAq9tj0yzhQu1uDVYbKoTnLg'
        this.params.qw_external_userid = await getCurExternalContactPolling()
      }
      await this.getMemberMappingInfo()
      await this.getDataSource()
      await this.updateLabel()
      // let { crm_yw_id, qw_external_userid } = this.params
      // if (crm_yw_id || qw_external_userid) {
      //   this.getDataSource()
      // } else {
      //   // let id = await getCurExternalContactPolling()
      //   let id = 'wm1DICCQAA2fBhi6LAlc2VXN9yjmflNA'
      //   this.params.qw_external_userid = id
      //   await this.getMemberMappingInfo()
      //   await this.getDataSource()
      // }
    },
    // 更新客户会员信息
    setMemberData(data = {}) {
      this.memberData = data
    },
    // 编辑称呼input事件
    onInput(event) {
      this.appellationValue = event.target.value
    },
    onBlur() {
      this.appellationValue = this.appellationValue.substring(0, 10)
      this.maxlength = 10
    },
    onFocus() {
      this.maxlength = 20
    },
    // 编辑客户称呼
    async editAppellation() {
      const { appellationValue, dataSource } = this
      const { crm_yw_id, qw_external_userid } = dataSource
      const body = {
        qw_external_userid: qw_external_userid || crm_yw_id,
        content: appellationValue
      }
      const { data, code } = await setMemberAppellation(body)
      this.modalVisible = false
      if (code == 200) {
        Toast.success('编辑成功!')
        this.getDataSource()
      } else {
        Toast.error('编辑失败!')
      }
    },
    // 显示编辑称呼弹窗
    onShowModal() {
      const { appellation } = this.dataSource
      if (appellation) this.appellationValue = appellation
      this.modalVisible = true
    }
    //提交跟进记录
    // async onSubmit() {
    //   try {
    //     const { recordText, dataSource } = this
    //     const { qw_external_userid } = dataSource
    //     if (recordText.length === 0) {
    //       return Toast('请记录用户所说的重点！')
    //     }
    //     await addFlollow({
    //       external_userid: qw_external_userid,
    //       followup_type_content: recordText
    //     })
    //     Toast.success('添加成功！')
    //     this.recordText = ''
    //     this.clickFouce = false
    //     this.getDataSource()
    //     if (this.$refs.dynamic) {
    //       this.$refs.dynamic.initData()
    //     }
    //   } catch (error) {
    //     console.log(error)
    //     Toast.fail('添加失败！')
    //   }
    // }
  }
}
</script>

<style>
::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  height: 0 !important;
  -webkit-appearance: none;
  background: transparent;
}
#app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
</style>
<style lang="less" scoped>
.customer-name {
  max-width: 75px;
}
.clf6 {
  color: #ff6f00 !important;
}
.cl29 {
  color: #1d2129;
}
.cl9c {
  color: #86909c;
}
.mb6 {
  margin-bottom: 6px;
}
.pb3 {
  padding-bottom: 3px;
}
.mb4 {
  margin-bottom: 4px;
}
// 主题按钮
.handle-btn {
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: @theme-color;
  font-weight: 500;
  padding: 0;
  margin: 0;
  border-radius: 5px;
  font-size: 14px;
  line-height: 14px;
  border: none;
}
// 主题按钮-禁用
.handle-btn-d {
  opacity: 0.6;
}
.flex-justify-evenly {
  width: 100%;
  justify-content: space-evenly;
}
.container-view {
  height: 100vh;
  overflow-y: scroll;
}
.customer-info-header {
  padding-top: 14px;
  margin-bottom: 2px;

  .customer-image {
    width: 58px;
    height: 58px;
    border-radius: 5px;
  }
  .customer-detail {
    .customer-info-detail {
      height: 16px;

      .customer-corp {
        color: #07c160;
        margin-left: 4px;
      }
      .customer-sex-icon {
        width: 12px;
        height: 12px;
        margin-left: 4px;
        margin-right: 4px;
      }
    }
    .grade-text {
      height: 20px;
      line-height: 20px;
      padding: 0 8px;
      margin-left: 6px;
    }
  }
  .user-record-write {
    margin-bottom: 2px;

    :deep(.van-field) {
      height: 114px;
      border-radius: 5px;
      box-sizing: border-box;
      border: 1px solid #dfdfdf;
      padding: 12px 8px 8px 12px;

      .van-field__body {
        height: 60px;
      }
      .van-field__control {
        height: 60px !important;
      }
    }
    .cover-layer {
      width: 100%;
      height: 100%;
      background-color: #dfdfdf;
      opacity: 0.3;
      border-radius: 5px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
    }
    .user-record-handle {
      position: absolute;
      right: 8px;
      bottom: 8px;
      z-index: 200;

      .submit-btn {
        width: 74px;
        height: 32px;
        line-height: 32px;
        border-radius: 5px;
      }
      .write-icon {
        width: 34px;
        height: 34px;
      }
    }
  }
  .handle-btn {
    width: 60px;
    height: 32px;
    color: @theme-color;
    background: @tab-back;
    border-radius: 4px;
  }
  .orange-theme {
    color: #ff6f00;
    background: rgba(255, 111, 0, 0.1);
  }
  .log-content {
    padding: 8px 12px;

    .log-icon {
      width: 16px;
      height: 16px;
      vertical-align: bottom;
    }
  }
}
.tabs-nav-wrap {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;

  &::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 1px;
    background: #f2f3f5;
  }
  .tab-nav {
    height: 44px;
    line-height: 44px;
    padding: 0 12px;
  }
  .tab-nav-active {
    font-weight: 500;
    color: @theme-color;

    &::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 150;
      width: 2em;
      height: 2px;
      background: @theme-color;
    }
  }
}
.hint-modal-view {
  height: 122px;
}
.handle-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 8px;
  padding-bottom: calc(8px + env(safe-area-inset-bottom) / 2) !important;

  .handle-icon {
    width: 24px;
    height: 24px;
  }
}
.handle-disabled {
  color: #c9cdd4;
}
.appellation-modal {
  .appellation-input {
    width: 276px;
    height: 44px;
    border-radius: 4px;
    padding-left: 12px;
    padding-right: 60px;
    border: 1px solid #e5e6eb;
  }
  .appellation-input-count {
    position: absolute;
    top: 14px;
    right: 12px;
  }
}
</style>
